import React from "react"

function CalendarInstance() {
	return (
		<iframe
			src="https://calendar.google.com/calendar/b/1/embed?height=600&amp;wkst=2&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FVienna&amp;src=bDRxbGtzZDgyZDJhZzFia2ljOHB1NDRxbGdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=aWo1cmVzcGM2bHFpN2Y5ZWVkYmcxZWlkbnNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=NDgzY3RzbjZkZzIydWp0NW9uczlvOHBmcHNAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;color=%23F4511E&amp;color=%234285F4&amp;color=%238E24AA&amp;mode=AGENDA&amp;showNav=0&amp;showDate=0&amp;showPrint=0&amp;showTz=1&amp;title=NonCon2020%20Edited%20Talks%20Release%20Schedule"
			width="100%"
			height="100%"
			frameBorder="0"
			scrolling="no"
			title="calendar"></iframe>
	)
}

export default CalendarInstance
